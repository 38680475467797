import { autoinject, bindable, containerless } from 'aurelia-framework';

@autoinject()
@containerless()
export class ThreatChannelIndicator {
    @bindable() public title: string;
    private iconClass: string;


    private bind() {
        switch (this.title) {
            case 'Network Threat':
                this.iconClass = 'fa-network-wired';
                break;
            case 'Universal Threat Management':
                this.iconClass = 'fa-block-brick-fire';
                break;
            case 'Endpoint Detection and Response':
                this.iconClass = 'fa-laptop-medical';
                break;
            default:
                this.iconClass = "fa-laptop";
        }
    }
}


