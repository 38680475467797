import { bindable, containerless } from 'aurelia-framework';
import {
    BusinessMap,
    CaseBook,
    CaseBookLightweightExtended,
    SicAlertOccurrence,
    ThreatSummary
} from 'services/cyber-api';
import { Utilities } from 'utilities/utilities';
import { Toast } from 'utilities/toast';
import { Router } from 'aurelia-router';

@containerless
export class ThreatHeader {
    @bindable private threatSummary: ThreatSummary;
    @bindable private businessMap: BusinessMap;
    @bindable private loading = true;
    @bindable private cases: CaseBook[] = [];
    @bindable private flagged = false;
    @bindable private archivedAt: Date | undefined = undefined;

    @bindable private close: () => void = undefined;
    @bindable private ignore: () => void = undefined;
    @bindable private flag: () => void = undefined;
    @bindable private unFlag: () => void = undefined;
    @bindable private showOccurrences: () => void = undefined;
    @bindable private createCase: () => void = undefined;
    @bindable private addToCase: (caseBook: CaseBookLightweightExtended) => void = undefined;

    private canClose = false;
    private canIgnore = false;
    private canCreateCase = false;

    constructor(
        private router: Router,
    ) {
    }

    public async bind(): Promise<void> {
        if (this.close)
            this.canClose = true;
        if (this.ignore)
            this.canIgnore = true;
        if (this.createCase)
            this.canCreateCase = true;
    }

    /**
     * Copies the value to the clipboard
     */
    private copyValue(value: string): void {
        try {
            Utilities.copyToClipboard(value);
        } catch (error) {
            Toast.warning(`Unable to copy ${value} to clipboard`);
            return;
        }

        Toast.info(`${value} copied to clipboard`);
    }

    /**
     * Generates a link to this specific threat and copies it to the clipboard
     */
    private copyLink(threatId: string): void {
        try {
            // Generate a direct link to this threat based on its short id
            const directLink = this.router.generate('threats', {
                id: threatId
            }, { absolute: true });
            // Copy link to clipboard
            Utilities.copyToClipboard(directLink);
        } catch (error) {
            Toast.warning('Unable to copy link to Threat to clipboard');
            return;
        }

        Toast.info('Link to Threat copied to clipboard');
    }
}
